@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins/Poppins-ExtraBold.ttf');
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins/Poppins-Bold.ttf');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins/Poppins-SemiBold.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins/Poppins-Medium.ttf');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins/Poppins-Regular.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins/Poppins-Light.ttf');
  font-display: swap;
  font-weight: 300;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:  #F8F8F8;  
}

.table {
  border-spacing: 0 15px;
}

i {
  font-size: 1rem !important;
}

.table tr {
  border-radius: 20px;
}

tr td:nth-child(n+5),
tr th:nth-child(n+5) {
  border-radius: 0 .625rem .625rem 0;
}

tr td:nth-child(1),
tr th:nth-child(1) {
  border-radius: .625rem 0 0 .625rem;
}
